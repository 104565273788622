// @ts-nocheck - getTextStyle seems to give issues
import { Input as ChakraInput, InputProps } from "@chakra-ui/react";
import { getTextStyle, TextType } from "components/Text_DEPRECATED";
import * as theme from "lib/styles/theme";

type Props = InputProps;
/**
 * @deprecated - use Input from beta instead
 * @param props
 * @returns
 */
export default function Input(props: Props): JSX.Element {
  const genericTextStyle = getTextStyle(TextType.PARAGRAPH);
  return (
    <ChakraInput
      {...props}
      fontFamily={theme.default.fonts.body}
      {...genericTextStyle}
    />
  );
}
