import {
  BoxProps,
  Button as ChakraButton,
  ButtonProps,
} from "@chakra-ui/react";
import Text, { TextType } from "components/Text_DEPRECATED";
import React from "react";

/**
 * Primary - Black button / white text
 * Secondary - White button / black text
 */
export enum ButtonType {
  PRIMARY = "PRIMARY",
  PRIMARY_BLACK = "PRIMARY_BLACK",
  SECONDARY = "SECONDARY",
  TEXT_BUTTON = "TEXT_BUTTON",
  NAV_INACTIVE = "NAV_INACTIVE",
  NAV_SECONDARY = "NAV_SECONDARY",
  TEXT_BUTTON_BLACK = "TEXT_BUTTON_BLACK",
  TEXT_BUTTON_RED = "TEXT_BUTTON_RED",
  CANCEL = "CANCEL",
  CANCEL_SECONDARY = "CANCEL_SECONDARY",
  CANCEL_SECONDARY_BLACK = "CANCEL_SECONDARY_BLACK",
}

export enum TextTransform {
  UPPERCASE = "uppercase",
  NONE = "none",
}

interface OwnProps {
  // defaults to primary
  styleType?: ButtonType;
  textType?: TextType;
  textTransform?: string;
  textDecoration?: string;
  isDisabled?: boolean;
  children: React.ReactNode;
}

export type Props = OwnProps & ButtonProps;

/**
 * Generic Chakra Button wrapper to allow easy styleguide matching / updates
 * @deprecated - use Button from beta instead instead
 * @param props
 */
export default function Button(props: Props): JSX.Element {
  const {
    styleType,
    textType,
    textTransform,
    textDecoration,
    isDisabled,
    ...otherProps
  } = props;
  const { buttonStyle, textStyle } = aggregateStyles({
    isDisabled,
    styleType,
    textDecoration,
    textTransform,
    textType,
  });

  return (
    <ChakraButton
      height={props.height || "64px"}
      minHeight={props.minH || "64px"}
      {...buttonStyle}
      {...otherProps}
      disabled={props.isDisabled}
    >
      <Text {...textStyle} type={props.textType || TextType.BUTTON}>
        {props.children}
      </Text>
    </ChakraButton>
  );
}

function getTextColor(styleType?: ButtonType): string | undefined {
  switch (styleType) {
    case ButtonType.TEXT_BUTTON:
      return "brand.Branding";
    case ButtonType.TEXT_BUTTON_BLACK:
      return "textAndShapes.Input";
    case ButtonType.TEXT_BUTTON_RED:
      return "brand.DangerText";
    case ButtonType.PRIMARY_BLACK:
      return "primary.7";
    default:
      return undefined;
  }
}

/**
 * Setup styles for the known Primary and Secondary types
 * @param props
 */
function aggregateStyles(options: {
  styleType?: ButtonType;
  textType?: TextType;
  textTransform?: string;
  textDecoration?: string;
  isDisabled?: boolean;
}): {
    buttonStyle: Partial<ButtonProps>;
    textStyle: Partial<BoxProps>;
  } {
  const { styleType, isDisabled, textTransform, textDecoration } = options;
  let transform = undefined;
  if (textTransform) {
    transform = textTransform as TextTransform;
  }
  const formattedTextColor = getTextColor(styleType);

  switch (styleType) {
    case ButtonType.PRIMARY:
    default:
      return {
        buttonStyle: {
          backgroundColor: isDisabled ? "primary.3" : "brand.Branding",
          borderRadius: "4px",
          cursor: "pointer",
          flex: 1,
        },
        textStyle: {
          textColor: "primary.7",
          textTransform: transform ?? "uppercase",
        },
      };
    case ButtonType.PRIMARY_BLACK:
      return {
        buttonStyle: {
          backgroundColor: isDisabled ? "primary.3" : "textAndShapes.Input",
          borderColor: isDisabled ? "primary.3" : "textAndShapes.Input",
          borderRadius: "4px",
          borderWidth: isDisabled ? undefined : "1px",
          boxSizing: "border-box",
          cursor: "pointer",
        },
        textStyle: {
          textColor: "primary.7",
          textTransform: transform ?? "uppercase",
        },
      };
    case ButtonType.SECONDARY:
      return {
        buttonStyle: {
          _hover: {
            backgroundColor: "primary.6",
            cursor: "pointer",
          },
          backgroundColor: "primary.7",
          borderColor: isDisabled ? "primary.3" : "primary.1",
          borderRadius: "4px",
          borderWidth: "1px",
        },
        textStyle: {
          textColor: isDisabled ? "primary.3" : "primary.1",
          textTransform: "uppercase",
        },
      };
    case ButtonType.CANCEL:
      return {
        buttonStyle: {
          _hover: {
            backgroundColor: "primary.5",
            borderColor: "textAndShapes.InputField",
            cursor: "pointer",
          },
          backgroundColor: isDisabled
            ? "primary.1"
            : "background.BasicDecorationGray",
          borderRadius: "4px",
          borderWidth: isDisabled ? undefined : "1px",
        },
        textStyle: {
          color: isDisabled ? "primary.1" : "textAndShapes.Input",
          textTransform: transform ?? "uppercase",
        },
      };
    case ButtonType.CANCEL_SECONDARY:
      return {
        buttonStyle: {
          _hover: {
            backgroundColor: "primary.6",
            borderColor: "brand.Branding",
            cursor: "pointer",
            textColor: "brand.Branding",
          },
          backgroundColor: isDisabled ? "primary.1" : "primary.7",
          borderColor: isDisabled ? "primary.1" : "brand.Branding",
          borderRadius: "4px",
          borderWidth: isDisabled ? undefined : "2px",
        },
        textStyle: {
          _hover: {
            textColor: "brand.Branding",
          },
          color: isDisabled ? "primary.1" : "brand.Branding",
          textTransform: "uppercase",
        },
      };
    case ButtonType.CANCEL_SECONDARY_BLACK:
      return {
        buttonStyle: {
          _hover: {
            backgroundColor: "primary.4",
            borderColor: "brand.Branding",
            cursor: "pointer",
          },
          backgroundColor: isDisabled ? "primary.1" : "primary.7",
          borderColor: isDisabled ? "primary.1" : "textAndShapes.InputField",
          borderRadius: "4px",
          borderWidth: isDisabled ? undefined : "2px",
          boxSizing: "border-box",
        },
        textStyle: {
          _hover: {
            textColor: "primary.3",
          },
          color: isDisabled ? "primary.1" : "primary.1",
          textTransform: "uppercase",
        },
      };
    // NOTE TO SELF -- can combine TEXT_BUTTON, TEXT_BUTTON_BLACK , TEXT_BUTTON_RED
    // just need to the the text color out of this method into a separate one
    case ButtonType.TEXT_BUTTON:
    case ButtonType.TEXT_BUTTON_BLACK:
    case ButtonType.TEXT_BUTTON_RED:
      return {
        buttonStyle: {
          _hover: {
            backgroundColor: "primary.7",
            borderRadius: "4px",
            cursor: "pointer",
          },
          backgroundColor: "primary.7",
          marginY: "0px",
          paddingY: "0px",
          variant: "ghost",
        },
        textStyle: {
          textColor: formattedTextColor,
          textDecoration: textDecoration ?? "underline",
          textTransform: transform || "uppercase",
        },
      };
    case ButtonType.NAV_INACTIVE:
      return {
        buttonStyle: {
          _hover: {
            backgroundColor: "primary.7",
            borderRadius: "4px",
            cursor: "pointer",
          },
          backgroundColor: "primary.7",
        },
        textStyle: {
          fontSize: "16px",
          textTransform: "uppercase",
        },
      };
  }
}
